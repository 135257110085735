import request from "@/utils/request";
const api = {
  getconstructionWorkerlist: "/dsmcore/api/constructionWorker/list", //查询施工人员清单接口--已派单
  // addconstructionWorker: "/dsmcore/api/constructionWorker/add", //派单
  // updateconstructionWorker: "/dsmcore/api/constructionWorker/update", //指定工人派单
  getconstructionDetaillist: "/dsmcore/api/constructionDetail/list", //查询施工人员清单接口---已排期施工项

  updateconstructionDetail: "/dsmcore/api/constructionDetail/update", //派单
  getworkerTreelist: "/dsmcore/api/worker/workerTree", //根据工种查询工人的接口
  constructionDetailoperation: "/dsmcore/api/constructionDetail/operation", //派单管理--生成派单项、合并施工项、解除合并
  getdispatchWorklist: "/dsmcore/api/dispatchWork/list", //派单管理--派单信息
  updatedispatchWork: "/dsmcore/api/dispatchWork/update", //派单管理--更新派单信息
  deletedispatchWork: "/dsmcore/api/dispatchWork/delete", //派单管理--取消派单信息
};
export function GetConstructionWorkerList(parameter) {
  return request({
    url: api.getconstructionWorkerlist,
    method: "post",
    params: parameter,
    //   data: parameter,
  });
}
export function GetConstructionDetailList(parameter) {
  return request({
    url: api.getconstructionDetaillist,
    method: "post",
    params: parameter,
    //   data: parameter,
  });
}
// export function AddConstructionDetail(parameter) {
//   return request({
//     url: api.addconstructionDetail,
//     method: "post",
//     params: parameter,
//     //   data: parameter,
//   });
// }

export function GetWorkerTreeList(parameter) {
  return request({
    url: api.getworkerTreelist,
    method: "post",
    params: parameter,
    // data: parameter,
  });
}

export function UpDateconstructionDetail(parameter) {
  return request({
    url: api.updateconstructionDetail,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}

export function ConstructionDetailOperation(parameter) {
  return request({
    url: api.constructionDetailoperation,
    method: "post",
    params: parameter,
    // data: parameter,
  });
}
export function GetDispatchWorkList(parameter) {
  return request({
    url: api.getdispatchWorklist,
    method: "post",
    params: parameter,
    // data: parameter,
  });
}
export function UpdateDispatchWork(parameter) {
  return request({
    url: api.updatedispatchWork,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function DeleteDispatchWork(parameter) {
  return request({
    url: api.deletedispatchWork,
    method: "post",
    params: parameter,
    // data: parameter,
  });
}
