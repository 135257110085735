<template>
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUsers"
      ref="SSearch"
      v-if="SSearchShow"
    ></s-search>
    <div class="table-button table-button-flex">
      <div>
        <a-tooltip>
          <template slot="title">
            将一个或多个施工项生成派单项方可派单
          </template>
          <a-button type="primary" @click="GenerateSingleitem('', '3')"
            >生成派单项</a-button
          >
        </a-tooltip>
        <a-button
          type="plain"
          :disabled="selectedRowKeys.length < 2"
          @click="GenerateSingleitem('', '1')"
          >合并施工项</a-button
        >
      </div>
      <div>
        <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
          >刷新</a-button
        >
        <columns-set
          tableName="ScheduledConstruction"
          :columns="columns"
          @changeColumns="changeColumns"
        ></columns-set>
      </div>
    </div>
    <!-- :scroll="{ x: '1100px', y: 'calc(82vh - 150px )' }" -->
    <!-- tableLayout="fixed" -->
    <!--   :rowSelection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }" -->
    <s-table
      ref="table"
      size="small"
      rowKey="cdId"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: '1100px', y: 'calc(82vh - 150px )' }"
      tableLayout="fixed"
      :row-selection="rowSelection"
    >
      <!--  @change="changeTable" -->
      <!-- <span slot="wsBcId" slot-scope="text">
        <div>{{ CompaniesResult(text) }}</div>
      </span> -->
      <span slot="serial" slot-scope="text, record, index">
        {{ (pageNo - 1) * pageSize + index + 1 }}
      </span>
      <span slot="tworksite" slot-scope="text">
        <div>{{ text.wsName }}</div>
      </span>
      <span slot="cdPId" slot-scope="text, record">
        <div>{{ record.tproject ? record.tproject.pname : "" }}</div>
      </span>
      <span slot="tproject" slot-scope="text">
        {{ text ? (text.tworkerType ? text.tworkerType.wtType : "") : "" }}
      </span>

      <span slot="cdExpectStartTime" slot-scope="text, record">
        <div v-if="text == ''">{{ "-" }}</div>
        <div v-else>
          {{ $Format(text).slice(0, 10) }} ~
          {{ $Format(record.cdExpectEndTime).slice(0, 10) }}
        </div>
      </span>

      <span slot="cdDuration" slot-scope="text">
        <div>{{ text + "小时" }}</div>
      </span>
      <!-- 操作 -->
      <!-- <span slot="action" slot-scope="text, record" class="action">
        <a
          v-if="
            isShowBtns.indexOf(
              'ScheduledConstruction-/api/constructionDetail/operation'
            ) > -1
          "
          @click="GenerateSingleitem('3', record)"
          >生成派单项</a
        >
      </span> -->
    </s-table>
    <a-drawer
      :title="DrawerTitle"
      placement="right"
      :closable="true"
      :visible="visibleDrawer"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
      :width="750"
      :destroyOnClose="true"
    >
      <!-- <AssignWorker
        @onClose="onClose"
        :record="record"
        ref="AssignWorker"
        v-if="AssignWorkerShow"
        @recordChangeFn="recordChangeFn"
      />

      <SendOrders
        @onClose="onClose"
        :record="record"
        :AssignWorkerValue="AssignWorkerValue"
        ref="SendOrders"
        v-if="SendOrdersShow"
      /> -->
      <!-- @tabledataChange="($event) => tabledataChange()" -->
    </a-drawer>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { SelectOptions } from "@/config/SelectOptions.js";
import { getWorkSitelist } from "@/api/apiJF/schedule";
import {
  GetConstructionDetailList,
  ConstructionDetailOperation,
} from "@/api/apiJF/order";
import { GetCompanies } from "@/api/device";
// import AssignWorker from "./AssignWorker.vue";
// import SendOrders from "./SendOrders.vue";
import moment from "moment";
// 搜索条件
const queryItems = [
  {
    label: "管家",
    value: "cdWsWIdGj",
    type: "input",
    defaultValue: "",
  },
  {
    label: "分公司",
    value: "cdBcIds",
    type: "select",
    selectOption: "CompanyListUnAll",
    defaultValue: "",
  },
  {
    label: "工地名称",
    value: "cdWsName",
    type: "input",
    defaultValue: "",
  },
  {
    label: "施工周期",
    value: "cdExpectTime",
    type: "dateRange",
    range: ["cdExpectStartTime", "cdExpectEndTime"],
    keyType: "WorkerAttend",
    defaultValue: [],
  },
  {
    label: "施工项",
    // value: "cdPName",
    type: "input",
    defaultValue: "",
  },
  {
    label: "工人岗位",
    // value: "cdPName",
    type: "select",
    defaultValue: "",
    selectOption: "WorkType",
  },
  {
    label: "施工项类型",
    // value: "cdPName",
    type: "select",
    defaultValue: "",
    selectOption: "TprojectList",
  },
];
// const columns = [
//   {
//     title: "序号",
//     scopedSlots: { customRender: "serial" },
//     width: "70px",
//     dataIndex: "serial",
//     align: "center",
//   },
//   {
//     title: "工地",
//     // width: "100px",
//     scopedSlots: { customRender: "tworksite" },
//     dataIndex: "tworksite",
//     ellipsis: true,
//     // align: "center",
//   },
//   {
//     title: "施工项",
//     width: "150px",
//     dataIndex: "cdPId",
//     scopedSlots: { customRender: "cdPId" },
//     // align: "center",
//   },
//   {
//     title: "所需时间",
//     width: "100px",
//     scopedSlots: { customRender: "cdDuration" },
//     dataIndex: "cdDuration",
//     align: "center",
//   },
//   {
//     title: "施工周期",
//     width: "200px",
//     scopedSlots: { customRender: "cdExpectStartTime" },
//     dataIndex: "cdExpectStartTime",
//     align: "center",
//   },
//   {
//     title: "工人岗位",
//     width: "100px",
//     dataIndex: "tproject",
//     scopedSlots: { customRender: "tproject" },
//     align: "center",
//   },

//   {
//     title: "所需工人数",
//     width: "100px",
//     dataIndex: "cdPeople",
//     align: "center",
//   },
//   //   {
//   //     title: "指定工人情况",
//   //     width: "110px",
//   //     dataIndex: "cdReceiveName",
//   //     align: "center",
//   //   },
//   {
//     title: "操作",
//     width: "100px",
//     scopedSlots: { customRender: "action" },
//     fixed: "right",
//     dataIndex: "action",
//     align: "center",
//   },
// ];

export default {
  name: "ScheduledConstruction",
  components: {
    STable,
    SSearch,
    ColumnsSet,
    // AssignWorker,
    // SendOrders,
  },
  data() {
    this.queryItems = queryItems;
    // this.columns = columns;
    return {
      tableColumns: [],
      SSearchShow: true,
      DrawerTitle: "",
      visibleDrawer: false,
      // 查询参数
      queryParam: {
        cdWsWIdGj: "",
        cdBcIds: "",
        cdWsName: "",
        cdExpectStartTime: "",
        cdExpectEndTime: "",
        cdPName: "",
        cdDispatchState: "1",
        // cdState: "1",

        pageNo: 1,
        pageSize: 10,
      },
      isShowBtns: [],
      record: "",
      CompanyList: {},
      type: "",
      filters: {},
      CompanyListid: [],
      pageNo: 1,
      pageSize: 10,
      AssignWorkerShow: false,
      SendOrdersShow: false,
      AssignWorkerValue: "",
      routeWsId: "",
      selectedRowKeys: [],
      selectedRows: [],
      dataList: [],
      AllCdIds: [],
      columns: [
        {
          title: "序号",
          //   scopedSlots: { customRender: "serial" },
          width: "70px",
          dataIndex: "serial",
          align: "center",
          customRender: (value, row, index) => {
            let number = (this.pageNo - 1) * this.pageSize + index + 1;
            const obj = {
              children: number,
              attrs: {},
            };
            obj.attrs.rowSpan = row.rowSpan || 0;
            // console.log("row.rowSpan", row.rowSpan);
            // console.log("row", row);

            return obj;
          },
        },
        {
          title: "工地",
          //   width: "200px",
          scopedSlots: { customRender: "tworksite" },
          dataIndex: "tworksite",
          ellipsis: true,
          // align: "center",
        },
        {
          title: "施工项",
          width: "150px",
          dataIndex: "cdPId",
          scopedSlots: { customRender: "cdPId" },
          // align: "center",
        },
        {
          title: "所需时间",
          width: "100px",
          scopedSlots: { customRender: "cdDuration" },
          dataIndex: "cdDuration",
          align: "center",
        },
        {
          title: "施工周期",
          width: "200px",
          scopedSlots: { customRender: "cdExpectStartTime" },
          dataIndex: "cdExpectStartTime",
          align: "center",
        },
        {
          title: "工人岗位",
          width: "100px",
          dataIndex: "tproject",
          scopedSlots: { customRender: "tproject" },
          align: "center",
        },
        {
          title: "所需工人数",
          width: "100px",
          dataIndex: "cdPeople",
          align: "center",
        },
        {
          title: "操作",
          width: "100px",
          //   scopedSlots: { customRender: "action" },
          fixed: "right",
          dataIndex: "action",
          align: "center",
          customRender: (value, row, index) => {
            //   <a onclick={this.GenerateSingleitem.bind("3", row)}>
            //   生成派单项
            // </a>
            const obj = {
              children: [
                <a onclick={this.GenerateSingleitem.bind(3, row)}>
                  生成派单项
                </a>,
              ],
              attrs: {},
            };

            obj.attrs.rowSpan = row.rowSpan || 0;
            if (index < this.dataList.data.length - 1) {
              if (!this.dataList.data[index + 1].rowSpan) {
                obj.children.push(<br />);

                obj.children.push(
                  <a onclick={this.GenerateSingleitem.bind(2, row)}>解除合并</a>
                );
              } else {
              }
            }

            return obj;
          },
        },
      ],
    };
  },

  created() {
    this.queryItems = JSON.parse(JSON.stringify(queryItems));
    this.tableColumns = this.columns;
    this.changeColumns();
    this.filters = SelectOptions;
    // console.log("this.$route.params", this.$route.params);
    let params = JSON.parse(JSON.stringify(this.$route.params));
    // console.log("params", params);
    this.routeWsId = params.id;
    // if (this.$route.params) {
    if (JSON.stringify(this.$route.params) != "{}") {
      if (params.cdBcIds) {
        let params = {};
        params = JSON.parse(JSON.stringify(this.$route.params));
        // params = JSON.parse(this.$route.params);
        for (let item in params) {
          // 默认值改变
          let queryItems = JSON.parse(JSON.stringify(this.queryItems));
          queryItems.forEach((el) => {
            if (el.value == "cdExpectTime") {
              // el.defaultValue = [
              //   moment(params.cdExpectStartTime * 1000),
              //   moment(params.cdExpectStartTime * 1000),
              // ];
            } else if (el.value == item) {
              el.defaultValue = params[item];
            }
          });
          this.queryItems = queryItems;
          // 查询值的改变
          let queryParam = JSON.parse(JSON.stringify(this.queryParam));
          for (let index in queryParam) {
            if (index == item) {
              queryParam[index] = params[item];
            }
          }
          this.queryParam = queryParam;
        }
      } else {
        // this.queryItems = queryItems;
        // location.reload();
      }
      //   console.log(" this.queryParam ", this.queryParam);
      //   console.log(" this.queryItems ", this.queryItems);
    }
    this.GetCompaniesId();
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtnsJF").split(",");
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [
          {
            key: "all-data",
            text: "选择全部",
            onSelect: () => {
              //   this.selectedRowKeys = [...Array(46).keys()]; // 0...45
              this.selectedRowKeys = this.AllCdIds;
              console.log("选择全部", this.selectedRowKeys);
            },
          },
          {
            key: "page-data",
            text: "选择当页",
            onSelect: () => {
              let cdIds = [];
              this.dataList.data.forEach((el) => {
                cdIds.push(el.cdId);
              });
              this.selectedRowKeys = cdIds; // 0...45
              console.log("选择当页this.selectedRowKeys", this.selectedRowKeys);
            },
          },
        ],
        onSelection: this.onSelection,
      };
    },
  },
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          // console.log(res);
          // this.filters.CompanyList.length = 0;

          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            this.CompanyList[el.bc_id] = el.bc_name;
          });
        })
        .then(() => {
          if (this.queryParam.cdBcIds == "") {
            this.queryParam.cdBcIds = this.CompanyListid.toString();
            this.$refs.table.refresh(true);
          }
          this.GetAllConstructionDetailList();

          //当包含的分公司小于1个的时候，查询条件不显示分公司查询 表格不显示分公司名称
          if (this.CompanyListid.length < 1) {
            let data = [...this.queryItems];
            this.queryItems = data.filter((item) => item.label != "公司名称");
            if (this.queryItems.length == 0) {
              this.SSearchShow = false;
            }
            let data1 = [...this.columns];
            this.tableColumns = data1.filter(
              (item) => item.title != "公司名称"
            );
            this.columns = data1.filter((item) => item.title != "公司名称");
          }
        });
    },
    CompaniesResult(a) {
      return this.CompanyList[a];
    },
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("ScheduledConstructionColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = this.columns.filter((el) =>
        value.includes(el.dataIndex)
      );
      this.tableColumns = selectColumns.length ? selectColumns : this.columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      if (parameter) {
        this.queryParam.pageNo = parameter.pageNo;
        this.queryParam.pageSize = parameter.pageSize;
        this.pageNo = parameter.pageNo;
        this.pageSize = parameter.pageSize;
      } else {
      }

      if (this.queryParam.cdBcIds != "") {
        let newObject = {};
        for (let el in this.queryParam) {
          if (this.queryParam[el] != "" && this.queryParam[el]) {
            newObject[el] = this.queryParam[el];
          }
        }
        let orderParam = Object.keys(newObject);
        const param = Object.assign({}, parameter, this.queryParam);
        const requestParameters = this.$order(param, orderParam);
        const params = {
          ...requestParameters,
          verify: this.$verify(requestParameters, orderParam),
        };
        return GetConstructionDetailList(params).then((res) => {
          // res.data.pageNo = res.data.page;
          // res.data.totalCount = res.data.sum;
          console.log("未派单列表", res.data.data);
          // return res.data;
          // let data = res;
          this.dataList = res.data;
          if (res.data.data && res.data.data.length > 0) {
            let startItem = this.dataList.data[0];
            startItem.rowSpan = 1;
            if (this.dataList.data.length > 0) {
              this.dataList.data.forEach((item, index) => {
                let nextItem = this.dataList.data[index + 1] || {};
                if (item.cdCommonNumber && nextItem.cdCommonNumber) {
                  if (item.cdCommonNumber === nextItem.cdCommonNumber) {
                    startItem.rowSpan++;
                  } else {
                    startItem = nextItem;
                    startItem.rowSpan = 1;
                  }
                } else {
                  // nextItem.serial = index + 1;
                  startItem = nextItem;
                  startItem.rowSpan = 1;
                }
              });
            }
          }

          console.log("res.data", res.data);

          //   return res.data;
          return res.data.data ? this.dataList : res;
        });
      }
    },
    // 获取全部数据
    GetAllConstructionDetailList() {
      let newqueryParam = {
        cdWsWIdJl: "",
        cdBcIds: "",
        cdWsName: "",
        cdExpectStartTime: "",
        cdExpectEndTime: "",
        cdPName: "",
        cdDispatchState: "1",
      };
      //   console.log("this.queryParam", this.queryParam);

      for (let el in newqueryParam) {
        // console.log("el", el);
        // console.log("this.queryParam[el]", this.queryParam[el]);
        // console.log("newqueryParam[el]", newqueryParam[el]);

        newqueryParam[el] = this.queryParam[el];
        // console.log(el, newqueryParam[el], this.queryParam[el]);
      }
      let newObject = {};
      for (let el in newqueryParam) {
        if (this.queryParam[el] != "" && this.queryParam[el]) {
          newObject[el] = this.queryParam[el];
        }
      }
      let orderParam = Object.keys(newObject);
      const param = Object.assign({}, newqueryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetConstructionDetailList(params).then((res) => {
        res.data.forEach((el) => {
          this.AllCdIds.push(el.cdId);
        });
      });
    },
    fetchUsers(value) {
      // this.queryParam = value;
      console.log("value", value);
      for (let el in this.queryParam) {
        this.queryParam[el] = value[el];
      }
      // console.log("this.queryParam", this.queryParam, value);
      if (this.queryParam.cdBcIds == "") {
        this.queryParam.cdBcIds = this.CompanyListid.toString();
      }
      this.queryParam.cdDispatchState = 1;
      this.GetAllConstructionDetailList();
      this.$refs.table.refresh(true);
    },
    onClose() {
      this.visibleDrawer = false;
      this.$refs.table.refresh(true);
    },
    afterVisibleChange(val) {},
    // 指定
    showDrawer(showPage, record, name) {
      console.log(showPage, name, record);
      this.DrawerTitle = name;
      this.visibleDrawer = true;
      this.AssignWorkerShow = false;
      this.SendOrdersShow = false;
      this[showPage] = true;
      this.record = JSON.stringify(record);
    },
    // 指定工人数据改变
    recordChangeFn() {
      // this.AssignWorkerObject = JSON.parse(this.$refs.AssignWorker.newRecord);
      this.AssignWorkerValue = this.$refs.AssignWorker.newRecord;
      // console.log("recordChangeFn", this.AssignWorkerValue);
    },
    // 生成派单项    // 合并施工项
    GenerateSingleitem(record, operate) {
      //   console.log("生成派单项GenerateSingleitem", operate, record);
      console.log("record", record);
      console.log("operate", operate);
      let newoperate = "";
      if (operate.target) {
        console.log("operate.target", operate.target.innerHTML);

        if (operate.target.innerHTML == "解除合并") {
          newoperate = 2;
        } else {
          newoperate = 3;
        }
      } else {
        newoperate = operate;
      }
      let queryParam = {
        cdIds: record ? record.cdId : this.selectedRowKeys.toString(),
        // cdIds: "15,16,17",
        operate: newoperate,
      };
      console.log("queryParam", queryParam);

      let orderParam = ["cdIds", "operate"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return ConstructionDetailOperation(params).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功");
          this.$refs.table.refresh(true);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log(selectedRowKeys, selectedRows);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // ConstructionDetailOperation
  },
};
</script>
<style lang="less" scoped>
.editBtn {
  margin-right: 20px;
}
.table-button-flex {
  display: flex;
  justify-content: space-between;
}
</style>